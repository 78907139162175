<template>
  <div>
    <NbModal
      v-if="Object.keys(order).length > 1"
      :id="id"
      modalConfig="modal-dialog-centered"
      width="76rem"
    >
      <template v-slot:header>
        <div class="d-flex justify-content-between mb-4">
          <h5 class="heading-4 ml-3 mb-1">{{ order.order_number }}</h5>
          <NbBadge
            v-if="order.current_status"
            class="fit-content ml-2"
            :type="getStatusType(order.current_status.name)"
            :text="order.current_status.name"
          />
          <NbBadge
            v-if="order.is_dispatched && contractDispatchable"
            class="fit-content ml-2"
            type="success"
            :text="$t('dispatched')"
          />
          <NbBadge
            v-if="order.prc"
            class="fit-content ml-2"
            type="warning"
            text="PRC"
          />
        </div>
      </template>
      <template v-slot:header-right>
        <NbButton class="mr-4" size="sm" @click="trackSearch(order.reference)">
          {{ $t("ordersListPage.toTrackingPage") }}
        </NbButton>
      </template>
      <div>
        <div class="d-flex justify-content-between mb-5">
          <NbCard
            id="document-card"
            :title="$t('orderViewPage.documentsLabel')"
            class="px-3"
            width="23.4rem"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap pt-3">
                <NbButton
                  class="w-48-5 my-1 px-2 text-left text-14-20"
                  variant="secondary"
                  @click="generateCommercialInvoice(orderId)"
                >
                  {{ $t("orderViewPage.generateInvoice") }}
                </NbButton>
                <NbButton
                  id="first-mile-label"
                  class="w-48-5 my-1 px-2 text-left text-14-20"
                  variant="secondary"
                  @click="generateFirstMile(orderId)"
                >
                  {{ $t("orderViewPage.generateFMLabel") }}
                </NbButton>
                <b-popover
                  placement="top"
                  target="first-mile-label"
                  triggers="hover focus"
                  variant="dark"
                >
                  {{ $t("orderViewPage.generateFirstMileLabel") }}
                </b-popover>
                <NbButton
                  class="w-48-5 my-1 px-2 text-left text-14-20"
                  variant="secondary"
                  @click="generatePackingSlipLabel(orderId)"
                >
                  {{ $t("ordersListPage.generateOnePackingSlip") }}
                </NbButton>
                <NbButton
                  id="last-mile-label"
                  class="w-48-5 my-1 px-2 text-left text-14-20"
                  variant="secondary"
                  @click="generateBoxLabel(orderId)"
                >
                  {{ $t("orderViewPage.generateLMTN") }}
                </NbButton>
                <b-popover
                  placement="bottom"
                  target="last-mile-label"
                  triggers="hover focus"
                  variant="dark"
                >
                  {{ $t("orderViewPage.generateLastMileLagel") }}
                </b-popover>
              </div>
            </template>
          </NbCard>
          <NbCard
            id="actions-card"
            :title="$t('actions')"
            class="px-3"
            width="23.4rem"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap pt-3">
                <NbButton
                  class="w-48-5 my-1 px-2 text-left text-14-20"
                  variant="secondary"
                  :disabled="
                    order.is_dispatched || order.overpack_id ? true : false
                  "
                  @click="editOrder"
                >
                  {{ $t("orderViewPage.editOrder") }}
                  <NbHelpText
                    v-if="order.is_dispatched || order.overpack_id"
                    id="popover-edit-btn"
                    class="text-gray-40 ml-1 float-right"
                  >
                    {{ dispatchOrOverpackMessage() }}
                  </NbHelpText>
                </NbButton>
                <NbButton
                  class="w-48-5 my-1 px-2 text-left text-14-20"
                  variant="secondary"
                  data-toggle="modal"
                  :data-target="`#${id}setNewOrderNumber`"
                  @click="newOrderNumber = order.order_number"
                >
                  {{ $t("orderViewPage.cloneOrder") }}
                </NbButton>
                <NbButton
                  class="w-48-5 my-1 px-2 text-left text-14-20"
                  variant="secondary"
                  data-toggle="modal"
                  :data-target="`#${id}reverse-modal`"
                  :disabled="isReversable"
                >
                  {{ $t("volumesReversePage.reverseVolume") }}
                  <NbHelpText
                    v-if="isReversable"
                    id="popover-reverse-btn"
                    class="text-gray-40 ml-1 float-right"
                  >
                    {{ $t("orderViewPage.isReversable") }}
                  </NbHelpText>
                </NbButton>
                <NbButton
                  class="w-48-5 my-1 px-2 text-left text-14-20"
                  variant="secondary"
                  data-toggle="modal"
                  :data-target="`#${id}modalDeleteOrder`"
                >
                  {{ $t("orderViewPage.deleteOrder") }}
                </NbButton>
              </div>
            </template>
          </NbCard>
          <NbCard
            id="recomended-card"
            :title="$t('orderViewPage.suggestedAction')"
            class="px-3"
            width="23.4rem"
          >
            <template #body>
              <div class="pt-3">
                <!-- <NbButton class="w-100 my-1 px-2 text-left">
                  {{ $t("ordersListPage.generateDocument") }}
                </NbButton> -->
                <NbButton
                  v-if="!order.is_dispatched && contractDispatchable"
                  class="w-100 my-1 px-2 text-left"
                  title="Dispatch This Order"
                  @click="dispatchOrder"
                >
                  {{ $t("dispatch") }}
                </NbButton>
              </div>
            </template>
          </NbCard>
        </div>

        <NbCard
          id="order-data-card"
          :title="$t('orderViewPage.orderData')"
          class="bg-gray-05 mb-4"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap pt-2">
              <NbTextField
                id="order_number"
                class="w-32 mb-3"
                :name="$t('orderViewPage.orderNumber')"
                variant="borderless-gray-10"
              >
                {{ order.order_number }}
              </NbTextField>
              <NbTextField
                id="sales_channel_name"
                class="w-32 mb-3"
                :name="$t('salesChannel')"
                variant="borderless-gray-10"
              >
                {{ order.sales_channel_name }}
              </NbTextField>
              <NbTextField
                id="sales_channel_order_number"
                class="w-32 mb-3"
                :name="$t('salesChannelOrderNumber')"
                variant="borderless-gray-10"
              >
                {{ order.sales_channel_order_number }}
              </NbTextField>
              <NbTextField
                id="shipper_name"
                class="w-32 mb-3"
                :name="$t('ordersListPage.sellersName')"
                variant="borderless-gray-10"
              >
                <span
                  class="link-1"
                  data-toggle="modal"
                  :data-target="`#${id}modal-shipper-name`"
                >
                  {{ order.shipper_name }}
                </span>
              </NbTextField>
              <NbTextField
                id="is_commercial_destination"
                class="w-32 mb-3"
                :name="$t('ordersListPage.isCommercialDestination')"
                variant="borderless-gray-10"
              >
                {{ order.is_commercial_destination }}
              </NbTextField>
              <NbTextField
                id="total_value"
                class="w-32 mb-3"
                :name="$t('totalValue')"
                variant="borderless-gray-10"
              >
                {{ standardizeValue(order.total_value) }}
              </NbTextField>
              <NbTextField
                id="reference"
                class="w-49 mb-3"
                :name="$t('trackingNumber')"
                variant="borderless-gray-10"
              >
                <span class="link-1" @click="trackSearch(order.reference)">
                  {{ order.reference }}
                </span>
              </NbTextField>
              <NbTextField
                id="volumes"
                class="w-49 mb-3"
                :name="$t('ordersListPage.firstMileTrackingNumber')"
                variant="borderless-gray-10"
              >
                {{ getFirstMileTrackingNumbers(order.volumes) }}
              </NbTextField>

              <NbTextField
                id="observations"
                class="w-100"
                :name="$t('orderViewPage.observations')"
                variant="borderless-gray-10"
              >
                {{ order.observations }}
              </NbTextField>
            </div>
          </template>
        </NbCard>
        <div
          v-if="Object.keys(contract).length > 1"
          class="d-flex justify-content-between mb-4"
        >
          <NbCard
            id="information-card"
            :title="$t('orderViewPage.shippingInformation')"
            class="bg-gray-05 w-49"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap pt-2">
                <NbTextField
                  id="observations"
                  class="w-100 mb-3"
                  :name="$t('ordersListPage.contractName')"
                  variant="borderless-gray-10"
                >
                  {{ contract.name }}
                </NbTextField>
                <NbTextField
                  id="incoterm"
                  class="w-49 mb-3"
                  :name="$t('orderViewPage.incoterm')"
                  variant="borderless-gray-10"
                >
                  {{ order.incoterm }}
                </NbTextField>
                <NbTextField
                  id="type_dm"
                  class="w-49 mb-3"
                  :name="$t('orderViewPage.type')"
                  variant="borderless-gray-10"
                >
                  {{ computedDeliveryMethod }}
                </NbTextField>
                <NbTextField
                  id="declared_value"
                  class="w-100 mb-3"
                  :name="$t('declaredValue')"
                  variant="borderless-gray-10"
                >
                  {{
                    order.declared_value == null
                      ? "0.00"
                      : standardizeValue(order.declared_value)
                  }}
                  ({{ $t("products") }}
                  {{ standardizeValue(order.products_value) }} +
                  {{ $t("freight") }}
                  {{ standardizeValue(order.freight_value) }})
                </NbTextField>
                <NbTextField
                  id="tax_value"
                  class="w-100 mb-3"
                  :name="$t('taxValue')"
                  variant="borderless-gray-10"
                >
                  {{
                    order.tax_value == null
                      ? "0.00"
                      : standardizeValue(order.tax_value)
                  }}
                  ({{ $t("dutiesTax") }}
                  {{ standardizeValue(order.duties_tax_value) }} +
                  {{ $t("salesTax") }}
                  {{ standardizeValue(order.sales_tax_value) }})
                </NbTextField>
                <NbTextField
                  id="freight_value"
                  class="w-49 mb-3"
                  :name="$t('freightValue')"
                  variant="borderless-gray-10"
                >
                  {{ standardizeValue(order.freight_value) }}
                </NbTextField>
                <NbTextField
                  id="return_insurance"
                  class="w-49 mb-3"
                  :name="$t('orderViewPage.haveInsurance')"
                  variant="borderless-gray-10"
                >
                  {{ order.return_insurance ? $t("yes") : $t("no") }}
                </NbTextField>
                <NbTextField
                  id="insurance_cost"
                  class="w-49 mb-3"
                  :name="$t('insuranceCost')"
                  variant="borderless-gray-10"
                >
                  {{ standardizeValue(order.insurance_cost) }}
                </NbTextField>
                <NbTextField
                  id="insurance_coverage"
                  class="w-49 mb-3"
                  :name="$t('ordersListPage.insuranceCoverage')"
                  variant="borderless-gray-10"
                >
                  {{ standardizeValue(order.insurance_coverage) }}
                </NbTextField>
                <NbTextField
                  v-if="order.overpack_id"
                  id="overpack_reference"
                  class="w-100 mb-3"
                  :name="$t('orderViewPage.overpackReference')"
                  variant="borderless-gray-10"
                >
                  <div
                    class="link-1"
                    @click="getOverpackModal(order.overpack_id)"
                  >
                    {{ overpack.reference }}
                  </div>
                </NbTextField>
                <NbTextField
                  id="address"
                  class="w-100"
                  :name="$t('orderViewPage.shipFrom')"
                  variant="borderless-gray-10"
                >
                  {{
                    order.seller.address +
                    ", " +
                    order.seller.address_number +
                    " - " +
                    order.seller.city +
                    " / " +
                    order.seller.country
                  }}
                </NbTextField>
              </div>
            </template>
          </NbCard>
          <NbCard
            id="customer-card"
            :title="$t('orderViewPage.customerInformation')"
            class="bg-gray-05 w-49"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap pt-2">
                <NbTextField
                  id="customer_full_name"
                  class="w-100 mb-3"
                  :name="$t('name')"
                  variant="borderless-gray-10"
                >
                  {{ order.customer_full_name }}
                </NbTextField>
                <NbTextField
                  id="customer_document"
                  class="w-49 mb-3"
                  :name="order.customer_document_type"
                  variant="borderless-gray-10"
                >
                  {{ order.customer_document_number }}
                </NbTextField>
                <NbTextField
                  id="customer_postal_code"
                  class="w-49 mb-3"
                  :name="$t('postalCode')"
                  variant="borderless-gray-10"
                >
                  {{ order.customer_postal_code }}
                </NbTextField>
                <NbTextField
                  id="customer_address"
                  class="w-100 mb-3"
                  :name="$t('address')"
                  variant="borderless-gray-10"
                >
                  {{
                    order.customer_address +
                    (order.customer_address_number
                      ? " " + order.customer_address_number
                      : "") +
                    (order.customer_address_complement
                      ? ", " + order.customer_address_complement
                      : "") +
                    (order.customer_address_reference
                      ? " (" + order.customer_address_reference + ") "
                      : "") +
                    " - " +
                    order.customer_city
                  }}
                </NbTextField>
                <NbTextField
                  id="customer_state"
                  class="w-49 mb-3"
                  :name="$t('state')"
                  variant="borderless-gray-10"
                >
                  {{ order.customer_state }}
                </NbTextField>
                <NbTextField
                  id="customer_country"
                  class="w-49 mb-3"
                  :name="$t('country')"
                  variant="borderless-gray-10"
                >
                  {{ order.customer_country }}
                </NbTextField>
                <NbTextField
                  id="customer_phone"
                  class="w-100 mb-3"
                  :name="$t('phone')"
                  variant="borderless-gray-10"
                >
                  {{ order.customer_phone }}
                </NbTextField>
                <NbTextField
                  id="customer_email"
                  class="w-100"
                  :name="$t('email')"
                  variant="borderless-gray-10"
                >
                  {{ order.customer_email }}
                </NbTextField>
              </div>
            </template>
          </NbCard>
        </div>

        <GeneralCostsCardView
          class="mb-4"
          :generalCostsTable="generalCostsTable"
          :order="order"
        />

        <BoxesCardView
          class="mb-4"
          :order="order"
          :volumeCheckpoints="volumeCheckpoints"
          :contract="contract"
        />
      </div>

      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
        <NbFooter
          class="mt-1"
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </template>
    </NbModal>
    <NbModal :id="`${id}modalDeleteOrder`" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="d-flex">
          <NbPageTitle :title="$t('orderViewPage.deleteConfirm')" />
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            class="mr-2"
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}modalDeleteOrder`"
          >
            {{ $t("cancel") }}
          </NbButton>
          <NbButton variant="primary" @click="deleteOrder">
            {{ $t("delete") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <NbModal :id="`${id}setNewOrderNumber`" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="d-flex">
          <NbPageTitle :title="$t('orderViewPage.orderNumberMustBeUnique')" />
        </div>
      </template>
      <template v-slot:body>
        <NbTextInput
          :id="`profundidade-${id}`"
          class="mr-2"
          :name="$t('orderViewPage.newOrderNumber')"
          :placeholder="$t('orderViewPage.newOrderNumber')"
          v-model="newOrderNumber"
          required
        />
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton class="mr-2" variant="secondary" data-dismiss="modal">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton
            variant="primary"
            :disabled="newOrderNumber == order.order_number"
            @click="cloneOrder"
          >
            {{ $t("confirm") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <NbModal :id="`${id}reverse-modal`" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="d-flex">
          <NbPageTitle :title="$t('volumesReversePage.reverseVolume')" />
        </div>
      </template>
      <template v-slot:body>
        <div v-if="checkReverses">
          <div
            class="accordion"
            id="accordionShowVolumesToReverse"
            v-for="(volume, index) in order.volumes"
            :key="index"
          >
            <h6 v-if="index === 0">
              {{ $t("orderViewPage.orderVolumes") }}
            </h6>
            <div
              v-if="!volume.volume_reverse"
              class="border border-gray-10 rounded py-1 px-3 py-2 my-2"
            >
              {{ $t("orderViewPage.volume") }}#{{ index + 1 }}
              <NbButton
                size="sm"
                @click="createVolumeReverse(volume.id)"
                title="Create Volume Reverse"
                class="my-1 font-weight-bold float-right"
                data-dismiss="modal"
                aria-label="Close"
              >
                {{ $t("orderViewPage.createVolumeReverse") }}
              </NbButton>
              <ul class="list-inline mb-0">
                <li class="list-inline-item font-weight-bold">
                  {{
                    volume.order_items.reduce(
                      (tot, elt) => tot + elt.quantity,
                      0
                    )
                  }}
                  {{
                    volume.order_items.reduce(
                      (tot, elt) => tot + elt.quantity,
                      0
                    ) > 1
                      ? "items:"
                      : "item: "
                  }}
                </li>
                <li
                  v-for="(orderItem, index) in volume.order_items"
                  :key="index"
                  class="list-inline-item"
                >
                  {{ orderItem.name }};
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-else>
          {{ $t("orderViewPage.noVolumes") }}
        </div>
        <div
          class="accordion"
          id="accordionShowVolumesToReversed"
          v-for="(volume, index) in order.volumes"
          :key="index"
        >
          <hr v-if="index === 0" />
          <h6 v-if="index === 0 && volume.volume_reverse">
            {{ $t("orderViewPage.volumesInReverse") }}
          </h6>
          <router-link
            v-if="volume.volume_reverse"
            :to="'volume_reverses/' + volume.volume_reverse.id"
            class="my-1"
            data-dismiss="modal"
            aria-label="Close"
          >
            {{ $t("orderViewPage.volume") }} #{{ index + 1 }} (
            {{ volume.order_items.reduce((tot, elt) => tot + elt.quantity, 0) }}
            {{
              volume.order_items.reduce((tot, elt) => tot + elt.quantity, 0) > 1
                ? "items)"
                : "item)"
            }}
          </router-link>
          <div v-else-if="index === 0">
            {{ $t("orderViewPage.noReversedVolumes") }}
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("cancel") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <NbModal
      v-if="Object.keys(order).length > 1"
      :id="`${id}modal-shipper-name`"
      modalConfig="modal-dialog-centered"
      width="45rem"
    >
      <template v-slot:header>
        <div class="d-flex">
          <NbPageTitle :title="$t('ordersListPage.sellerInformations')" />
        </div>
      </template>
      <template v-slot:body>
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextField
            id="shipper_name"
            class="w-49 mb-3"
            :name="$t('ordersListPage.sellersName')"
            variant="borderless-gray-10"
          >
            {{ order.shipper_name }}
          </NbTextField>
          <NbTextField
            id="seller_rfb_code"
            class="w-49 mb-3"
            :name="$t('ordersListPage.sellerRfbCode')"
            variant="borderless-gray-10"
          >
            {{ order.seller_rfb_code }}
          </NbTextField>
          <NbTextField
            id="seller_legal_name"
            class="w-49 mb-3"
            :name="$t('ordersListPage.sellerLegalName')"
            variant="borderless-gray-10"
          >
            {{ order.seller_legal_name }}
          </NbTextField>
          <NbTextField
            id="shipper_address"
            class="w-49 mb-3"
            :name="$t('address')"
            variant="borderless-gray-10"
          >
            {{ order.shipper_address }}
          </NbTextField>
          <NbTextField
            id="shipper_address_number"
            class="w-49 mb-3"
            :name="$t('addressNumber')"
            variant="borderless-gray-10"
          >
            {{ order.shipper_address_number }}
          </NbTextField>
          <NbTextField
            id="shipper_address_complement"
            class="w-49 mb-3"
            :name="$t('complement')"
            variant="borderless-gray-10"
          >
            {{ order.shipper_address_complement }}
          </NbTextField>
          <NbTextField
            id="shipper_zip_code"
            class="w-49 mb-3"
            :name="$t('postalCode')"
            variant="borderless-gray-10"
          >
            {{ order.shipper_zip_code }}
          </NbTextField>
          <NbTextField
            id="shipper_state"
            class="w-49 mb-3"
            :name="$t('state')"
            variant="borderless-gray-10"
          >
            {{ order.shipper_state }}
          </NbTextField>
          <NbTextField
            id="shipper_country"
            class="w-49 mb-3"
            :name="$t('country')"
            variant="borderless-gray-10"
          >
            {{ order.shipper_country }}
          </NbTextField>
          <NbTextField
            id="shipper_city"
            class="w-49 mb-3"
            :name="$t('city')"
            variant="borderless-gray-10"
          >
            {{ order.shipper_city }}
          </NbTextField>
          <NbTextField
            id="shipper_email"
            class="w-49 mb-3"
            :name="$t('email')"
            variant="borderless-gray-10"
          >
            {{ order.shipper_email }}
          </NbTextField>
          <NbTextField
            id="shipper_phone"
            class="w-49 mb-3"
            :name="$t('phone')"
            variant="borderless-gray-10"
          >
            {{ order.shipper_phone }}
          </NbTextField>
          <NbTextField
            id="shipper_tax_number"
            class="w-49 mb-3"
            :name="$t('ordersListPage.sellerTaxNumber')"
            variant="borderless-gray-10"
          >
            {{ order.shipper_tax_number }}
          </NbTextField>
          <NbTextField
            id="shipper_site"
            class="w-49 mb-3"
            :name="$t('site')"
            variant="borderless-gray-10"
          >
            {{ order.shipper_site }}
          </NbTextField>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton class="mr-2" variant="secondary" data-dismiss="modal">
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NProgress from "nprogress";
import moment from "moment";
import NbModal from "@/components/modal/NbModal.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbTextField from "@/components/input/text/NbTextField.vue";
import GeneralCostsCardView from "./GeneralCostsCardView.vue";
import BoxesCardView from "./BoxesCardView.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import { HS_CODES } from "@/constants";

import OrderService from "@/services/OrderService";
import VolumeReverseService from "@/services/VolumeReverseService";
import OrderLabelService from "@/services/OrderLabelService";
import PackingSlipService from "@/services/PackingSlipService";
import CheckpointService from "@/services/CheckpointService";

const orderService = new OrderService();
const volumeReverseService = new VolumeReverseService();
const orderLabelService = new OrderLabelService();
const packingSlipService = new PackingSlipService();
const checkpointService = new CheckpointService();

export default {
  components: {
    NbModal,
    NbPageTitle,
    NbButton,
    NbBadge,
    NbCard,
    NbTextField,
    GeneralCostsCardView,
    BoxesCardView,
    NbTextInput,
    NbHelpText,
    NbFooter,
  },
  name: "ModalOrderView",
  props: {
    id: {
      type: String,
      default: "modal-order-view",
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      newOrderNumber: "",
      trackingBaseUrl: process.env.VUE_APP_TRACKING_BASE_URL,

      generalCostsTable: [],
      order: {
        order_number: "",
        sales_channel_id: "",
        sales_channel_order_number: "",
        estimated_delivery_date: "",
        customer_full_name: "",
        customer_document_type: "",
        customer_document_number: "",
        customer_address: "",
        customer_address_number: "",
        customer_address_complement: "",
        customer_address_reference: "",
        customer_postal_code: "",
        customer_address_quarter: "",
        customer_city: "",
        customer_state: "",
        customer_country: "BR",
        customer_phone: "",
        customer_email: "",
        customer_mailbox: "",
        incoterm: "",
        total_value: "",
        observations: "",
        freight_value: "",
        freight_cost: "",
        handling_time: "",
        /* tax_cost: "", */
        insurance_cost: "",
        currency: "USD",
        quantity: "",
        has_insurance: false,
        volumes: [
          {
            id: "",
            height: "",
            width: "",
            length: "",
            weight: null,
            cubage_factor: "",
            volume_type: "",
            sales_tax_value: null,
            duties_tax_value: null,
            order_items: [
              {
                name: "",
                description: "",
                weight: null,
                value: "",
                hs_code: "",
                sku: "",
                quantity: "",
              },
            ],
          },
        ],
        contract_id: 1,
        last_mile_number: "",
        current_status: {
          name: "",
        },
        seller: {},
        sales_channel_name: "",
      },
      contract: {
        name: "",
        is_auto_tax_defined: null,
        last_mile_delivery_method: {
          type: null,
        },
      },
      overpack: {
        reference: "",
      },
      volumeCheckpoints: [
        {
          id: null,
          checkpoints: [
            {
              id: null,
              message: null,
              attachment: null,
              date_iso: null,
              created_at: null,
              checkpoint: {
                id: null,
                type: null,
                slug: null,
                name: null,
                description: null,
                created_at: null,
                tracking_code: null,
                title: null,
              },
            },
          ],
        },
      ],
    };
  },
  computed: {
    computedDuties() {
      //'Duties e a soma da diferenca de item.value_with_tax e item.value'
      let taxed_val = 0;
      let item_val = 0;

      this.order.volumes.forEach((volume) => {
        volume.order_items.forEach((item) => {
          if (item.value_with_tax) taxed_val += Number(item.value_with_tax);
          if (item.value) item_val += Number(item.value);
        });
      });
      return `${this.standardizeValue((item_val - taxed_val).toFixed(2))}`;
    },
    checkReverses() {
      let finalResult = false;
      if (Object.keys(this.order).length > 1) {
        this.order.volumes.forEach((volume) => {
          if (!volume.hasOwnProperty("volume_reverse")) {
            finalResult = true;
          }
        });
      }
      return finalResult;
    },
    isReversable() {
      if (this.contract && this.contract.reverse_delivery_method_id) {
        return false;
      }
      return true;
    },
    computedDeliveryMethod() {
      if (
        this.contract.last_mile_delivery_method &&
        this.contract.last_mile_delivery_method.type
      ) {
        return this.contract.last_mile_delivery_method.type;
      }
      return this.$t("orderViewPage.standard");
    },
  },
  methods: {
    reloadOverpacks() {
      this.$refs?.overpacksTable?.getData();
    },
    getOverpackModal(id) {
      if (id) {
        this.$helpers.openModal(this.id);
        this.$emit("openModal", { modalId: "modal-overpack-view", itemId: id });
        return;
      }
    },
    sumValues(arrayObjs) {
      if (arrayObjs) {
        let sumTotal = 0;
        arrayObjs.forEach((arrayObj) => {
          sumTotal += Number(arrayObj.value);
        });
        return sumTotal.toFixed(2);
      }
      return "0.00";
    },
    loadGeneralCostsTable() {
      let tableData = [];
      tableData = [
        {
          freight_cost: this.standardizeValue(this.order.freight_cost),
          duties: this.computedDuties,
          insurance: this.standardizeValue(this.order.insurance),
          general_costs: this.standardizeValue(
            this.sumValues(this.order.order_costs)
          ),
          fines: this.standardizeValue(0),
          other_costs: this.standardizeValue(0),
        },
      ];
      this.generalCostsTable = tableData;
    },
    getOverpack(id) {
      orderService.getOverpack(id).then((response) => {
        this.overpack = response.data.data;
        //this.$helpers.openModal("modal-order-view");
      });
    },
    getContract(id) {
      orderService.getContract(id).then((response) => {
        this.contract = response.data.data;
      });
    },
    getData() {
      if (this.orderId) {
        orderService
          .getOrder(this.orderId)
          .then((response) => {
            const order = response.data.data;
            this.order = order;
            this.loadGeneralCostsTable();
            this.getContract(this.orderId);
            this.getOverpack(this.orderId);
            this.order.estimated_delivery_date = moment(
              order.estimated_delivery_date
            ).format("YYYY-MM-DD");
            //this.order.return_insurance = order.return_insurance ? "YES" : "NO";
            /**
            /* Get volume checkpoints
            */
            this.volumeCheckpoints = [];
            for (let i = 0, l = this.order.volumes.length; i < l; i++) {
              this.volumeCheckpoints.push({
                id: this.order.volumes[i].id,
                checkpoints: [],
              });
            }

            checkpointService
              .getVolumeCheckpoints(this.orderId)
              .then((response) => {
                response.data.data.forEach((elt) => {
                  for (
                    let i = 0, l = this.volumeCheckpoints.length;
                    i < l;
                    i++
                  ) {
                    if (this.volumeCheckpoints[i].id === elt.id) {
                      this.volumeCheckpoints[i].checkpoints = elt.checkpoints;
                    }
                  }
                });
              });
          })
          .catch((error) => {
            if (error.response.status == 404) {
              this.$router.push("/");
            }

            this.$helpers.toast(error.message, "danger");

            NProgress.done();
          });
      }
    },
    generatePackingSlipLabel(orderId) {
      packingSlipService
        .generate(orderId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          this.$helpers.toast(error.message, "danger", 3000);

          NProgress.done();
        });
    },
    generateBoxLabel(orderId) {
      orderLabelService
        .generateBoxLabel(orderId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },
    /* generateShipperConfirmation(orderId) {
      orderLabelService
        .generateShipperConfirmation(orderId)
        .then((response) => {
          window.open(response.data.data);
        })
        .catch((error) => {
          this.$helpers.toast(error.message, "danger", 3000);

          NProgress.done();
        });
    }, */
    generateFirstMile(orderId) {
      orderLabelService
        .generateFirstMile(orderId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();
        });
    },
    generateCommercialInvoice(orderId, volumeId = {}) {
      orderLabelService
        .generateCommercialInvoiceLabel(orderId, volumeId)
        .then((response) => {
          if (
            response.data.hasOwnProperty("data") &&
            response.data.data.hasOwnProperty("download_url")
          ) {
            window.open(response.data.data.download_url);
          }
        })
        .catch((error) => {
          this.$helpers.toast(error.message, "danger", 3000);

          NProgress.done();
        });
    },
    dispatchOrder() {
      orderService
        .dispatchOrder(this.orderId)
        .then(() => {
          this.getData();
          this.$helpers.toast("Order dispatched", "success", 5000);
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger", 3000);
          });
        });
    },
    contractDispatchable() {
      if (this.contract && this.contract.slug) {
        const hasLion = this.contract.slug.toLowerCase().includes("lion");
        const isSmartDdu = this.contract.slug
          .toLowerCase()
          .includes("smart_ddu"); //correios
        if (hasLion || isSmartDdu) {
          return true;
        }
      }
      return false;
    },
    createVolumeReverse(volumeId) {
      const dataToSend = { volume_id: volumeId };
      volumeReverseService
        .setVolumeReverse(dataToSend)
        .then((response) => {
          this.$router
            .push(`/orders/volume_reverses/${response.data.data.id}`)
            .then(() => {
              this.$helpers.toast(
                "Volume Reverse has been created.",
                "success"
              );
            });
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger", 3000);
          });
        });
    },
    dispatchOrOverpackMessage() {
      if (this.order.is_dispatched) {
        return "Can not be edited because is dispatched";
      }
      if (this.order.overpack_id) {
        return "The order cannot be edited as it is already overpacked";
      }
    },
    trackSearch(traking_number) {
      if (traking_number) {
        const lastCharOfTrackingBaseUrl = this.trackingBaseUrl.slice(-1);
        //check the last trackingBaseUrl is a "/" and remove it to avoid router bugs like (...)/trackings/tracking making it like (...)/trackings?trackingNumber=tracking_number
        if (lastCharOfTrackingBaseUrl === "/") {
          this.trackingBaseUrl = this.trackingBaseUrl.slice(0, -1);
        }

        window.open(this.trackingBaseUrl + "?trackingNumber=" + traking_number);
        return;
      }
      location.replace(this.trackingBaseUrl + "404");
    },
    getFirstMileTrackingNumbers(volumes) {
      const trackingNumbers = volumes.map(
        (volume) => volume.first_mile_tracking_number
      );
      return trackingNumbers.join(", ");
    },
    simplifyCurrency() {
      if (this.order.currency === "USD") {
        return "$";
      } else {
        return this.order.currency;
      }
    },
    standardizeValue(val) {
      if (val && (typeof val === "number" || typeof val === "string")) {
        return this.simplifyCurrency() + parseFloat(val).toFixed(2);
      }
      return this.simplifyCurrency() + "0.00";
    },
    getStatusType(status) {
      let finalType = "success";
      if (!status) {
        return;
      }
      if (
        status == "PENDING" ||
        status == "FAILED_ATTEMPT" ||
        status == "EXCEPTION IN PICKUP"
      ) {
        finalType = "warning";

        return finalType;
      }

      if (
        status == "PARTIAL DELIVERY FAILED" ||
        status == "EXPIRED" ||
        status == "EXCEPTION"
      ) {
        finalType = "danger";

        return finalType;
      }

      return finalType;
    },
    transformString(str) {
      let words = str.split("_");

      for (let i = 0; i < words.length; i++) {
        words[i] =
          words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
      }
      return words.join(" ");
    },
    getCategory(hscode) {
      let finalCategory = "Others";
      for (var i in HS_CODES) {
        if (HS_CODES[i] == hscode) {
          finalCategory = i;
        }
      }
      return finalCategory;
    },
    editOrder() {
      let order = {
        courier: {
          contract_id: "",
          selectedQuote: null,
          /* 
          freight_value: this.order.freight_value,
          freight_cost: this.order.freight_cost,
          handling_time: this.order.handling_time,
          */
        },
        receiver: {
          customer_full_name: this.order.customer_full_name,
          customer_email: this.order.customer_email,
          customer_phone: this.order.customer_phone,
          customer_country: this.order.customer_country,
          customer_postal_code: this.order.customer_postal_code,
          customer_city: this.order.customer_city,
          customer_state: this.order.customer_state,
          customer_address: this.order.customer_address,
          customer_address_number: this.order.customer_address_number,
          customer_address_complement: this.order.customer_address_complement,
          customer_address_reference: this.order.customer_address_reference,
          customer_company_name: this.order.customer_company_name,
          customer_document_number: this.order.customer_document_number,
          customer_document_type: this.order.customer_document_type,
          is_commercial_destination: this.order.is_commercial_destination,
          seller_name: this.order.seller_name,
          seller_address: this.order.seller_address,
          seller_address_number: this.order.seller_address_number,
          seller_address_complement: this.order.seller_address_complement,
          seller_city: this.order.seller_city,
          seller_state: this.order.seller_state,
          seller_country: this.order.seller_country,
          seller_email: this.order.seller_email,
          seller_zip_code: this.order.seller_zip_code,
          seller_tax_number: this.order.seller_tax_number,
          seller_website: this.order.seller_website,
          seller_phone: this.order.seller_phone,
          seller_phone_code: this.order.seller_phone_code,
          seller_phone_number: this.order.seller_phone_number,
          seller_rfb_code: this.order.seller_rfb_code,
          seller_legal_name: this.order.seller_legal_name,
          seller_logo_url: this.order.seller_logo_url,
        },
        shipment: {
          sales_channel_id: this.order.sales_channel_id,
          sales_channel_order_number: this.order.sales_channel_order_number,
          order_number: this.order.order_number,
          incoterm: this.order.incoterm,
          return_insurance: this.order.return_insurance,
          volumes_attributes: this.order.volumes,
          quantity: this.order.quantity,
          currency: this.order.currency,
          is_landed_cost: this.order.is_landed_cost,
        },
      };
      this.order.volumes.forEach((element, indexOne) => {
        order.shipment.volumes_attributes[indexOne].volume_name = "Custom Box";
        order.shipment.volumes_attributes[indexOne].order_items_attributes =
          element.order_items;
        order.shipment.volumes_attributes[
          indexOne
        ].volume_type = `${Math.random().toString(16).slice(2)}`; //add box hash

        this.order.volumes[indexOne].order_items.forEach(
          (element, indexTwo) => {
            order.shipment.volumes_attributes[indexOne].order_items_attributes[
              indexTwo
            ].box = order.shipment.volumes_attributes[indexOne].volume_type;
            order.shipment.volumes_attributes[indexOne].order_items_attributes[
              indexTwo
            ].unit_price = element.value;
            order.shipment.volumes_attributes[indexOne].order_items_attributes[
              indexTwo
            ].category = this.getCategory(element.hs_code);
          }
        );
      });
      this.order.volumes.forEach((element, indexOne) => {
        order.shipment.volumes_attributes[indexOne].collected_icms =
          element.collected_icms;
        order.shipment.volumes_attributes[indexOne].collected_ii =
          element.collected_ii;
      });

      this.$helpers.closeAllModals();
      this.$helpers.saveUnsavedWork("order-create", order);
      this.$router.push({
        name: "create_order",
        params: {
          isFrom: "isEdit",
          editId: this.orderId,
        },
      });
    },
    cloneOrder() {
      let order = {
        courier: {
          contract_id: "",
          selectedQuote: null,
        },
        receiver: {
          customer_full_name: this.order.customer_full_name,
          customer_email: this.order.customer_email,
          customer_phone: this.order.customer_phone,
          customer_country: this.order.customer_country,
          customer_postal_code: this.order.customer_postal_code,
          customer_city: this.order.customer_city,
          customer_state: this.order.customer_state,
          customer_address: this.order.customer_address,
          customer_address_number: this.order.customer_address_number,
          customer_address_complement: this.order.customer_address_complement,
          customer_address_reference: this.order.customer_address_reference,
          customer_company_name: this.order.customer_company_name,
          customer_document_number: this.order.customer_document_number,
          customer_document_type: this.order.customer_document_type,
          is_commercial_destination: this.order.is_commercial_destination,
          seller_name: this.order.seller_name,
          seller_address: this.order.seller_address,
          seller_address_number: this.order.seller_address_number,
          seller_address_complement: this.order.seller_address_complement,
          seller_city: this.order.seller_city,
          seller_state: this.order.seller_state,
          seller_country: this.order.seller_country,
          seller_email: this.order.seller_email,
          seller_zip_code: this.order.seller_zip_code,
          seller_tax_number: this.order.seller_tax_number,
          seller_website: this.order.seller_website,
          seller_phone: this.order.seller_phone,
          seller_phone_code: this.order.seller_phone_code,
          seller_phone_number: this.order.seller_phone_number,
          seller_rfb_code: this.order.seller_rfb_code,
          seller_legal_name: this.order.seller_legal_name,
          seller_logo_url: this.order.seller_logo_url,
        },
        shipment: {
          sales_channel_id: this.order.sales_channel_id,
          sales_channel_order_number: this.order.sales_channel_order_number,
          order_number: this.newOrderNumber,
          incoterm: this.order.incoterm,
          return_insurance: this.order.return_insurance,
          volumes_attributes: this.order.volumes,
          quantity: this.order.quantity,
          currency: this.order.currency,
          is_landed_cost: this.order.is_landed_cost,
        },
      };
      /* CASO ALTERE A VARIAVEL PARA O JEITO QUE ESTAVA ANTES.
      if (order.shipment.return_insurance == "NO") {
        order.shipment.return_insurance = false
      } else {
        order.shipment.return_insurance = true
      }
      */
      this.order.volumes.forEach((element, indexOne) => {
        order.shipment.volumes_attributes[indexOne].volume_name = "Custom Box";
        order.shipment.volumes_attributes[indexOne].order_items_attributes =
          element.order_items;
        order.shipment.volumes_attributes[
          indexOne
        ].volume_type = `${Math.random().toString(16).slice(2)}`; //add box hash

        this.order.volumes[indexOne].order_items.forEach(
          (element, indexTwo) => {
            order.shipment.volumes_attributes[indexOne].order_items_attributes[
              indexTwo
            ].box = order.shipment.volumes_attributes[indexOne].volume_type;
            order.shipment.volumes_attributes[indexOne].order_items_attributes[
              indexTwo
            ].unit_price = element.value;
            order.shipment.volumes_attributes[indexOne].order_items_attributes[
              indexTwo
            ].category = this.getCategory(element.hs_code);
          }
        );
      });

      this.order.volumes.forEach((element, indexOne) => {
        order.shipment.volumes_attributes[indexOne].collected_icms =
          element.collected_icms;
        order.shipment.volumes_attributes[indexOne].collected_ii =
          element.collected_ii;
      });

      this.$helpers.closeAllModals();
      this.$helpers.saveUnsavedWork("order-create", order);
      this.$router.push({
        name: "create_order",
        params: {
          isFrom: "isClone",
        },
      });
    },
    deleteOrder() {
      orderService
        .deleteOrder(this.orderId)
        .then(() => {
          this.$emit("reloadOrders");
          this.$helpers.closeAllModals();
          this.$helpers.toast("Order deleted", "success");
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });
        });
    },
  },
  watch: {
    orderId() {
      this.getData();
    },
  },
};
</script>

<style scoped></style>
